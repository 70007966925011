// html, body, .root, main {
//     height: 100%;
// }
// .root > div {
//     height: 100%;
//     & > div {
//         height: 100%;
//     }
// }

@font-face {
    font-family: 'Helvetica';
    font-weight: 400;
    src: url('../fonts/HelveticaRegular.woff2') format('woff2'),
         url('../fonts/HelveticaRegular.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica';
    font-weight: 700;
    src: url('../fonts/HelveticaBold.woff2') format('woff2'),
         url('../fonts/HelveticaBold.woff') format('woff');
}

@font-face {
    font-family: 'Flavors';
    font-weight: normal;
    src: url('../fonts/flavors.woff2') format('woff2'),
         url('../fonts/flavors.woff') format('woff');
}

@keyframes fadein {
    from { opacity: 0 }
    to { opacity: 1 }
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
    color: #fff;
    background-color: #0e0e0e;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.container {
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    padding: 27px 30px 0;
    margin: 0 auto;
}

p {
    line-height: normal;
}

button {
    cursor: pointer;
    position: relative;
    padding: 0;
    input {
        opacity: 0;
        height: 0 !important;
        position: absolute;
        padding: 0 !important;
        margin: 0 !important;
    }
    &[disabled] {
        color: #999;
        background-color: #f3f3f3;
    }
}

a {
    text-decoration: none;
}

input {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

button {
    border: none;
}

/*slider*/
.slick-list,
.slick-slider {
    position: initial;
    display: block;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots {
    li {
        position: relative;
        display: inline-block;
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
    }
    button {
        width: 6px;
        height: 6px;
        cursor: pointer;
        border-radius: 50%;
        padding: 0;
        margin: 0 3px;
        color: transparent;
        border: 0;
        outline: none;
        background: #e0e0e0;
    }
    .slick-active {
        button {
            background: #000;
        }
    }
}

.slick-slide {
    float: left;
    font-size: 0;
}
