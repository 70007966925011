.main-page {
    height: 100vh;
}


.main-page .container {
    display: flex;
    flex-direction: column;
}

.main-title {
    font-family: 'Flavors', sans-serif;
    font-size: 36px;
    line-height: 1.3;
    text-align: center;
    color: #f1e6d2;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-bottom: 20px;

    span {
        font-size: 24px;
        line-height: 1.25;
        color: #ff8cb7;
        margin-top: 10px;
    }
}

p {
    text-align: center;
    line-height: 1.38;
    margin-bottom: 8px;
}

.ru .main-title {
    font-size: 22px;
}

.wr-slider {
    // flex-grow: 1;
    margin-top: 3vh;
    margin-bottom: 30px;
}

.wr-slider img {
    width: 100%;
}

.wr-slider .slick-list,
.wr-slider .slick-slider {
    position: relative;
}

.wr-slider .slick-dots li {
    width: 5px;
    height: 5px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    vertical-align: middle;
    margin: 0 4px;
}

.wr-slider .slick-dots button {
    width: 100%;
    height: 100%;
}

.wr-slider .slick-dots button {
    background-color: #ff8cb7;
    border-radius: 3px;
}

.wr-slider .slick-dots .slick-active button {
    background-color: #ff8cb7;
}

.wr-slider .slick-dots .slick-active {
    width: 30px;
}

.wr-slider .slick-dots {
    bottom: -30px;
}

.slide {
    display: flex;
    border-radius: 20px;
    border: solid 3px transparent;
    width: 270px;
    height: 270px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;

    img {
        width: 100%;
        height: 100%;
    }
}

.slick-active {
    .slide {
        border-color: #ff8cb7;
    }
}

// .slide-container {
//     padding: 8px 0;
// }

// .slick-slide {
//     margin: 0 15px;
// }

// .slick-list {
//     margin: 0 -15px;
// }

.slide-item {
    margin: 0 7px 14px;
}

.slide-item h2 {
    font-size: 16px;
    font-weight: bold;
    font-style: oblique;
    line-height: 1;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
}

.slide-image-container {
    max-width: 140px;
    border-radius: 16px;
    overflow: hidden;
}

.slide-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-item-active {
    position: relative;
}

.slide-item-active .slide-image-container {
    box-shadow: 0 0 120px 0 #7ecd21;
    border: 2px solid #7ecd21;
    box-sizing: border-box;
    max-width: 200px;
}

.btn-upload-foto {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    max-width: 310px;
    width: 90%;
    height: 48px;
    border-radius: 100px;
    background-color: #e4540c;
    display: block;
    flex-shrink: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin: 0 auto;

    &:hover {
        opacity: .6;
    }
}

.btn-another-foto {
    font-size: 15px;
    font-weight: bold;
    color: #f2bc19;
    display: flex;
    align-items: center;
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    &:hover {
        opacity: .6;
    }
}

.create-page h2 {
    font-weight: bold;
    color: #333;
    line-height: 1.38;
    text-align: center;
    margin-bottom: 20px;
}

.create-page .create-steps {
    line-height: 1.38;
    text-align: center;
    color: #999;
    margin-bottom: 8px;
}

.creatives {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
}

.creative {
    width: 48%;
    position: relative;
    margin: 0 0 15px;
}

.creative .num-check-foto {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #05b0ff;
    display: none;
    align-items: center;
    justify-content: center;
}

.creative .holder {
    position: relative;
    margin-bottom: 8px;
    padding-top: 100%;
    background-color: #eeeeee;
}

.creative.active .num-check-foto {
    display: flex;
}

.creative img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.creative.active img {
    // box-shadow: 0 0 40px 0 #c77dff;
    border: solid 3px #05b0ff;
    box-sizing: border-box;
}

.creative p {
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: #333;
    text-transform: capitalize;
}

.create-page .btn-upload-foto {
    position: fixed;
    bottom: 15px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.create-page .btn-upload-foto.disabled {
    background-color: #3b3c3e;
}

.collage-page {
    display: flex;
    flex-direction: column;
    // height: 94vh;
    // overflow: hidden;
    padding-bottom: 20px;
    .btn-upload-foto {
        max-width: 310px;
    }
    .btn-back {
        margin-bottom: 5.4vh;
    }
}

.btn-refresh-foto {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    max-width: 310px;
    width: 90%;
    height: 48px;
    background-color: transparent;
    display: block;
    flex-shrink: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    margin: 16px auto 0;
}

.collage-page .creative-holder {
    position: relative;
    max-width: 450px;
    width: 100%;
    margin: 0 auto 24px;

    .creative-holder-placeholder {
        padding-top: 100%;
    }

    .wait-video, video, img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 20;
    }

    .imageview-host {
        z-index: 20;
    }

    .wait-video {
        z-index: 5;
    }
}

.collage-page img,
.collage-page video {
    width: 100%;
    max-width: 450px;
    // max-height: 450px;
    height: 100%;
    display: block;
    margin: 0 auto;
}

.collage-page img {
    object-fit: cover;
}
.collage-page video {
    object-fit: contain;
}

.collage-page .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.collage-page-content {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.collage-page-text {
    margin-bottom: 27px;
}

/*loader*/
.loader {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;
}

.collage-page-text {
    margin-bottom: 27px;
}

.loader p {
    font-family: 'Flavors';
    font-size: 24px;
    line-height: 1.25;
    text-align: center;
    color: #f1e6d2;
    position: absolute;
    top: calc(40% + 220px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
}

.spinner-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 140px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
}

.avatar-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 140px;
    border: solid 5px #f1e6d2;
    box-sizing: border-box;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    animation-name: fadein;
    animation-duration: 320ms;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.spinner-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.triple-spinner {
    display: block;
    position: relative;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    border: 8px solid #fe5812;
    border-left-color: transparent;
    animation: spin 3s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 8px solid transparent;
}

.triple-spinner::before {
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-color: #f1e6d2;
    border-bottom-color: transparent;
    animation: spin-reverse 1.5s linear infinite;
}

.triple-spinner::after {
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    border-color: #ff8cb7;
    border-left-color: transparent;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-reverse {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

/*modal*/
.-show-popup {
    overflow: hidden;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 30;
    .bg-result {
        position: absolute;
        left: 0;
        width: 100%;
    }
    .bg-bottom {
        bottom: 0;
    }
    .bg-top {
        top: 0;
    }
}

.hidden-image {
    position: absolute;
    top: -9999px;
    left: -9999px;
    opacity: 0;
}

.modal {
    max-width: 380px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    z-index: 20;
    padding: 56px 40px;
    svg {
        width: 54px;
        display: block;
        margin: 0 auto 16px;
    }
    .btn-upload-foto {
        color: #fff;
        max-width: 100px;
        width: 100%;
        background-color: #0e0e0e;
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #000;
        margin-bottom: 24px;
    }
    .modal-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -5;
    }
    h3 {
        font-family: 'Flavors';
        font-size: 36px;
        line-height: 1;
        text-align: center;
        color: #000;
        margin-bottom: 24px;
    }
}

/*error-page*/
.error-page .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 94vh;
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.38;
}

.error-page svg {
    max-width: 264px;
    margin-bottom: 5.5vh;
}

.error-page h3 {
    font-weight: bold;
    margin-bottom: 16px;
}

.btn-back {
    font-size: 15px;
    font-weight: bold;
    color: #dfd4c2;
    display: flex;
    align-items: center;
    background-color: transparent;
    position: relative;
    top: -5px;
    left: -5px;
    padding: 5px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 9px;
}

.btn-back svg {
    width: 11px;
    margin-right: 10px;
}

.collage-page h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;
    text-align: center;
    color: #fff;
    width: 100%;
    margin-bottom: 16px;
    text-transform: uppercase;
}

.share-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    position: relative;
    // left: -30px;
    // width: calc(100% + 60px);
    padding-top: 12px;
    padding-bottom: 12px;
    // border-top: 1px solid #d2d2d2;
    // border-bottom: 1px solid #d2d2d2;
    // margin-top: 10px;
}

.share-container button {
    width: 40px;
    height: 40px;
    border: none;
    background: no-repeat;
    flex-shrink: 0;
    margin-left: 24px;

    &:last-child {
        width: 1px;
    }
}

.share-container svg {
    width: 100%;
}

.steps-progress {
    position: fixed;
    height: 4px;
    width: 100%;
    background-color: #e7e7e7;
    top: 0;
    left: 0;
    z-index: 50;

    span {
        transition: 500ms;
        background-color: #feca28;
        display: block;
        height: 100%;
    }
}

.tabs-container {
    display: flex;
    max-width: 450px;
    margin: 0 auto;
    width: 100%;

    button {
        font-family: 'Helvetica', sans-serif;
        line-height: 1.38;
        text-align: center;
        color: #fff;
        flex-grow: 1;
        flex-shrink: 1;
        border-bottom: 2px solid transparent;
        background: none;
        box-sizing: border-box;
        padding-bottom: 6px;
    }

    .active-tab {
        font-weight: bold;
        color: #e4540c;
        border-color: #e4540c;
    }
}

.wait-video {
    overflow: hidden;
    max-width: 450px;
    margin: 0 auto;

    img {
        filter: blur(7px);
    }
}

.loader-video {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
}

.loader-video .item-loader {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    animation: item-loader .9s linear infinite;
    margin: 0 6px;
}

.loader-video .first-item-loader {
    background-color: #1db8ff;
}

.loader-video .second-item-loader {
    background-color: #ffd300;
    animation: item-loader .9s linear infinite;
    animation-delay: .3s;
}

.loader-video .third-item-loader {
    background-color: #fc4700;
    animation: item-loader .9s linear infinite;
    animation-delay: .6s;
}


@keyframes item-loader {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.8, 1.8);
    }
}

@media all and (max-height: 660px) {
    .container {
        padding: 15px 15px 0;
    }

    .main-title {
        margin-bottom: 12px;
    }
}

@media all and (max-width: 500px) {
    .share-container {
        left: -30px;
        width: calc(100% + 60px);
    }
}